#header-fixed {
  background-color: white;
  position: fixed;
  z-index: 1;
  width: 100%;
}

#login-card {
  width: 80%;
  height: 100%;
  padding: 0;
  margin-top: 10px;
}

#kiosko-card {
  max-width: 600px;
  height: 100%;
  padding: 0;
  margin: 0 auto;
}

#register-card {
  width: 80%;
  padding: 0;
  margin-top: 10px;
}

#password-card {
  margin-left: 35%;
  margin-right: 35%;
  width: 30%;
  padding: 0;
  margin-top: 10px;

}

@media (max-width: 768px) {
  #menu-centered {
    display: flex; /* Convierte el menú en un contenedor flex */
    justify-content: center; /* Centra los elementos horizontalmente */
    align-items: center; /* Centra los elementos verticalmente */
    height: 60px; /* Ajusta la altura del menú según sea necesario */
    border-bottom: none; /* Elimina la línea inferior predeterminada (opcional) */
  }
  
  #menu-centered .ant-menu-item {
    padding: 0 0; /* Espaciado entre elementos del menú */
    text-align: center;
  }

  #header-fixed {
    background-color: white;
    position: fixed;
    z-index: 1;
    padding: 0;
    width: 100%;
  }
}

@media (max-width: 600px) {
  #login-card {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  #password-card {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  #register-card {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }
}

@media (max-width: 1000px) and (min-width: 601px) {
  #login-card {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  #password-card {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  #register-card {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  #img-center {
    max-width: 100%;
    height: auto;
  }

  #password-card {
    margin-left: 25%;
    margin-right: 25%;
    width: 50%;
  }
}

#img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 35vh;
  padding: 20px;
}

.center {
  align-items: center;
  justify-items: center;
}

#card {
  display: block;
  float: left;
  width: 300px;
  margin: 10px 10px 10px 0px;
}

#card:hover {
  cursor: pointer;
}

#content {
  width: 100%;
  padding: 1% 2%;
  margin-top: 64px;
  background: #c7c7c7;
}

#menuitem {
  float: right;
  height: 100%;
}

#menuitem-izquierda {
  max-width: 40vh;
  height: 100%;
}

.button-link {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: rgb(41, 149, 250);
  cursor: pointer;
}

.table {
  margin-top: 15px;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.th-border {
  border: 1px solid #dddddd;
  border-top: 0px;
  text-align: center;
  /* background-color: #00b5e2;
  color: rgb(223, 230, 236); */
  /* background: linear-gradient(0deg, rgba(0,181,226,.1) 0%, rgba(255,255,255,1) 50%); */
}

.th-border-up {
  border: 1px solid #dddddd;
  text-align: center;
}

.th-border-sin-right {
  border: 1px solid #dddddd;
  border-top: 0px;
  border-right: 0px;
}

.td-border{
  border: 1px solid #dddddd;
  text-align: left;
}

.td-border-center{
  border: 1px solid #dddddd;
  text-align: center;
}

.td-border-right{
  border: 1px solid #dddddd;
  text-align: right;
}

.td-border-sin-right{
  border: 1px solid #dddddd;
  border-right: 0px;
}

.zebra-gris{
  background-color: rgba(24, 144, 255, 0.1) !important;
}

.efo{
  background-color: rgba(255, 87, 82, 0.199) !important;
}

/* Drawer en la derecha */
.ant-drawer-right {
  right: 0 !important;
  left: auto !important;
}

/* Drawer en la izquierda */
.ant-drawer-left {
  left: 0 !important;
  right: auto !important;
}

/* Quita un estilo de antd en la tabla */

.ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}


.th-totales {
  /* border: 1px solid #dddddd; */
  /* border-top: 0px; */
  text-align: right;
  font-size: 17px;
}

.td-totales-right{
  /* border: 1px solid #dddddd; */
  text-align: right;
  font-size: 17px;
}

.td-totales-center{
  /* border: 1px solid #dddddd; */
  text-align: center;
  font-size: 17px;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled){
  background-color: #DCDCDC !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
}

/* Selected item select item */
/* .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: rgb(0, 181, 226, .3) !important;
} */

/*Listas para ElegirLeyenda*/

.styled-list {
  background-color: #fff;
  border-left: 4px solid #4A90E2;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0,0,0,0.05);
  padding: 14px 16px;
  max-width: 450px;
  font-size: 11.5px;
  color: #333;
}

.styled-list h3 {
  margin: 0 0 8px;
  font-size: 13px;
  font-weight: bold;
  color: #4A90E2;
}

.styled-list table {
  width: 100%;
  border-collapse: collapse;
}

.styled-list td {
  padding: 3px 0;
  vertical-align: top;
}

.styled-list code {
  background-color: #f2f2f2;
  padding: 1px 5px;
  border-radius: 3px;
  font-family: monospace;
  color: #c7254e;
}