body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#img-logo{
  width: auto;
  height: 100%;
  padding: 5px 20px;
  margin-bottom: 20px;
  max-width: 500px;
}

@media (max-width: 600px) {
  #img-logo{
    width: auto;
    height: 100%;
    padding: 5px 0px;
    margin-bottom: 20px;
    max-width: 500px;
  }
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.ant-message-rtl {
  direction: ltr !important;
}

.ant-message-rtl .anticon{
  margin-right: 8px !important;
  margin-left: 0px !important;
}

.ant-notification-notice-message .ant-notification-rtl {
  direction: ltr !important;
}

.ant-notification-notice-description .ant-notification-rtl{
  direction: ltr !important;

}

.ant-notification-topLeft {
  right: 0px !important;
  top: 24px !important;
  bottom: auto !important;
  left: auto !important;
}

.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-notice {
  margin-right: 0 !important;
  margin-left: auto !important;
}

.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn !important;
}

.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 24px !important;
  margin-left: 0px !important;
}

.ant-notification-rtl{
  direction: ltr !important;
}

.ant-notification-rtl .ant-notification-notice-icon{
  margin-left: 4px !important;
  margin-right: 0 !important;
}

.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px !important;
  padding-left: 0px !important;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 0px !important;
  margin-left: 48px !important;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 0px !important;
  margin-left: 48px !important;
}

.ant-notification-rtl .ant-notification-notice-close {
  right: 22px !important;
  left: auto !important;
}
.ant-notification-rtl .ant-notification-notice-btn {
  float: right !important;
}
