.input-item {
    border-bottom: 1px solid #E0FFFF !important; /* Cambiar a 'none' si sinBorde es true */
    border-radius: 2px !important;
    /* padding: 0px 11px!important; */
     /* Puedes ajustar este valor según necesites */
    background-color: #FFFFFF!important;
    transition: border-color 0.3s ease!important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1)!important;
}

.input-item.sin-borde {
    border-bottom: none;
    box-shadow: none;
}

.headers-item-small {
    color: black;
    text-align: center;
    font-size: 13px;
    border-radius: 1px;
    margin-top: 6px;
    margin: 3px;
}

.input-item.custom-disabled .ant-input[disabled],
.input-item.custom-disabled .ant-input[disabled]:hover,
.input-item.custom-disabled .ant-input[disabled]:focus {
    color: black !important;
    -webkit-text-fill-color: black !important; /* Para navegadores WebKit */
    opacity: 1 !important; /* Evita la apariencia desvanecida */
    background-color: white !important; /* Asegura que el fondo no cambie */
    cursor: not-allowed !important; /* Indica que el input está deshabilitado */
}


.box-item {
    border-top: 2px solid #00b5e2;
    border-radius: 1px;
    padding: 5px;
    margin: 0px 10px;
}

.custom-small-table .ant-table {
    font-size: 12px !important; /* Asegura que se aplique */
  }
  
  .custom-small-table .ant-table-thead > tr > th {
    font-size: 12px !important; /* Reduce el tamaño del encabezado */
  }
  
  .custom-small-table .ant-table-tbody > tr > td {
    font-size: 12px !important; /* Reduce el tamaño del contenido */
  }

    .custom-disabled .ant-select-selector {
      color: #757575 !important;
  }

    .custom-disabled .ant-picker-input > input {
      color: #757575 !important;
    }
  
  
  